import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditProgramType, BaseForm } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditProgramTypeDataService } from '../../services/data/audit-program-type-data.service';

@Component({
  selector: 'app-audit-program-type-item-form',
  templateUrl: './audit-program-type-item-form.component.html',
  styleUrls: ['./audit-program-type-item-form.component.scss'],
})
export class AuditProgramTypeItemFormComponent extends BaseForm<AuditProgramType> implements OnInit {
  contextVariables: any[] = [];
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditProgramTypeDataService
  ) {
    super(viewModeService, 'AUDIT_PROGRAM_TYPE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
